<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

      <div class="container">
        <div class="row mt-4 mb-4">
          <div class="col-md-12 ">
            <div class="quote-blk p-3">
              <h3>Your Quote</h3>
              <span>Congratulations! You have qualified for a discount of <span style="color:#254E90;font-weight: bolder"> KES.  {{(quotation.traditionalPremium - quotation.distancePremium) | currency('', 0)}} </span> </span>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <ul class="nav nav-tabs nav-fill" id="quotation-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" @click="switchPaymentMethod('Full Amount')"  id="home-tab-fill" data-toggle="tab" href="#pay-now" role="tab" aria-controls="home-fill" aria-selected="false">
                  <img src="@/assets/images/pay-now.png" class="img img-fluid" /> &nbsp;&nbsp; Pay Now
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link "  @click="switchPaymentMethod('Installments')" id="profile-tab-fill" data-toggle="tab" href="#pay-in-installments" role="tab" aria-controls="profile-fill" aria-selected="true">
                  <img src="@/assets/images/pay-installments.png" class="img img-fluid" /> &nbsp;&nbsp; Pay in installments
                </a>
              </li>
            </ul>

            <div class="tab-content" id="quotation-tab-content">
              <div class="tab-pane active" id="pay-now" role="tabpanel" aria-labelledby="home-tab-fill">
                <div class="row p-3">
                   <div class="col-3">
                     <h3>-{{ discount | currency('', 0) }}%</h3>
                   </div>
                  <div class="col-9">
                    <span class="float-right traditional-price">Traditional Price: KES.  {{ quotation.traditionalPremium | currency('', 0) }}</span> <br/>
                    <span class="float-right low-mileage-price">Low Mileage Price: KES.  {{ quotation.distancePremium | currency('', 0) }}</span> <br/>
                  </div>
                </div>
              </div>
              <div class="tab-pane " id="pay-in-installments" role="tabpanel" aria-labelledby="profile-tab-fill">
                <div class="p-3">
                  <div class="row" style="border: 1px solid #ccc;border-radius: 4px;margin-left: 10px;margin-right: 10px;">
                    <div class="col-6">
                      <span class="installments-primary">Total premium</span>
                    </div>
                    <div class="col-6">
                      <span class="float-right installments-primary">KES.  {{ quotation.financingBreakdown.totalFinancedPremium | currency('', 0) }}</span> <br/>
                      <span class="float-right installments-secondary">Split in {{ quotation.financingBreakdown.checkpoints.length - 1 }} payments.</span> <br/>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <table class="table">
                        <tr v-for="checkpoint in quotation.financingBreakdown.checkpoints">
                          <th>{{ checkpoint.description }}</th>
                          <th>{{ checkpoint.due_date }}</th>
                          <th>{{ checkpoint.amount | currency('', 0) }}</th>
                        </tr>
                      </table>

                      <div class="mt-3">
                        <table class="table">
                          <tr >
                            <td style="font-weight:700;font-family: 'Poppins', sans-serif;color:#000;font-size:1em;">Minimum Deposit </td>
                            <td style="font-size:1em;font-weight:500;font-family: 'Poppins', sans-serif;">KES. {{ quotation.financingBreakdown.minimumDeposit | currency('', 0) }}</td>
                          </tr>
                        </table>
                      </div>
                      <div>
                        <label for="paymentMethod" >
                          <input id="paymentMethod" value="Installments" type="radio" v-model="paymentMethod" /> &nbsp;&nbsp; <span style="text-decoration: underline;color:#254E90;font-weight: bolder;" >Select payment method. (Contribution Financing Agreement) </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div  class="quotation-details p-3" @click="showCoverDetails('details-about-your-cover')">
          <div class="row">
            <div class="col-2">
              <img src="@/assets/images/details_about_cover.png" />
            </div>
            <div class="col-10">
              <div class="quotation-details-primary">
                Details about your cover
              </div>
              <div class="quotation-details-secondary">
                Comprehensive cover runs for up to 10,000km
              </div>
            </div>
          </div>
        </div>

        <div  class="quotation-details p-3 mt-3" @click="showCoverDetails('included-your-cover')">
          <div class="row">
            <div class="col-2">
              <img src="@/assets/images/included_in_cover.png" />
            </div>
            <div class="col-10">
              <div class="quotation-details-primary">
                Included in your cover
              </div>
              <div class="quotation-details-secondary">
                Comprehensive cover, courtesy cab, windscreen etc
              </div>
            </div>
          </div>
        </div>

        <div  class="quotation-details p-3 mt-3" @click="showCoverDetails('device-installation')">
          <div class="row">
            <div class="col-2">
              <img src="@/assets/images/device_installations.png" />
            </div>
            <div class="col-10">
              <div class="quotation-details-primary">
                Device installation
              </div>
              <div class="quotation-details-secondary">
                A Telematics Box must be installed at the start of the policy.
              </div>
            </div>
          </div>
        </div>

        <div  class="quotation-details p-3 mt-3" @click="showCoverDetails('key-exclusions')">
          <div class="row">
            <div class="col-2">
              <img src="@/assets/images/exclusions.png" />
            </div>
            <div class="col-10">
              <div class="quotation-details-primary">
                Key exclusions & conditions
              </div>
              <div class="quotation-details-secondary">
                Losses not covered
              </div>
            </div>
          </div>
        </div>

        <div class="quote-blk car-details p-3 mt-3">
          <div class="row ">
              <div class="col-12">
                <h4>Car Details</h4>
              </div>
              <div class="col-12 mt-3">
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Value of the car : &nbsp;&nbsp;
                    <span >KES. {{ quotation.sumInsured | currency('', 0) }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Product:
                    <span > TIA Switch</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Cover Period:
                    <span >{{ quote_summary.cover_period }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Make:
                    <span >{{ quotation.make }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Model:
                    <span >{{ quotation.model }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    YoM:
                    <span >{{ quotation.yearOfManufacture }}</span>
                  </li>
                </ul>
              </div>
          </div>
        </div>

        <div class="quote-blk car-details p-3 mt-3">
          <div class="row ">
            <div class="col-12">
              <h4>Payment Option</h4>
            </div>
            <div class="col-12 mt-3">
              <div class="form-group">
                <label>
                  <input type="radio" v-model="paymentMethod" value="Full Amount" /> Full amount  ( <span style="font-weight: bolder;"> KES {{ quotation.distancePremium | currency('', 0) }} </span> )
                </label> <br/>
                <label>
                  <input type="radio" v-model="paymentMethod" value="Installments" /> Installments ( <span style="font-weight: bolder;"> KES {{ quotation.financingBreakdown.minimumDeposit | currency('', 0) }} </span> )
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <label for="termsAndConditions" >
            <input id="termsAndConditions" value="Installments" type="checkbox" v-model="termsAndConditions" /> &nbsp;&nbsp; <a target="_blank" href="https://tiaswitch.ke/terms-and-conditions" style="text-decoration: underline;color:#254E90;font-weight: bolder;" >Accept terms and conditions </a>
          </label>
        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-6">
            <a href="https://tiaswitch.ke/waiting-list" style="border: 1px solid #26BBCE;color:#26BBCE !important;" class="btn btn-lg quote-btn btn-block btn-outline-dark">  Buy Later</a>
          </div>
          <div class="col-6">
            <button style="background-color: #26BBCE;border: none !important;color:#fff !important;" @click="navigateToCoverWizard" class="btn  btn-lg  quote-btn btn-block btn-outline-danger">  Buy Now </button>
          </div>
        </div>
      </div>

    <modal :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="details-about-your-cover" >
      <div class="cover-modal p-3">

        <div class="row">
            <div class="col-8">
              <h3>Details about your cover</h3>
            </div>
            <div class="col-4">
              <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('details-about-your-cover')" class="btn btn-sm btn-info float-right"> Close </a>
            </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">Cover runs for up to 10,000Kms after which you need to top-up via mobile.</li>
            <li style="margin-bottom: 7px;">Failure to top-up kilometers on depletion immediately downgrades the cover to 3rd
              party until the renewal date.</li>
            <li style="margin-bottom: 7px;">Free Telematics Box must be installed in your vehicle at the start of your policy to track
              mileage, driving style and collision.</li>
            <li style="margin-bottom: 7px;">
              Telematics Box installation and valuation are done professionally and conveniently at a
              location of your choice.
            </li>
            <li style="margin-bottom: 7px;">
              Includes a Mobile App to track and top-up Kilometers as well as file and track claims
              progress. TIA Switch is underwritten by Takaful and supported by AiCare.
            </li>
            <li style="margin-bottom: 7px;">
              Note: Failure to have the telematics devise installed and valuation done within the first
              7 days from inception of the policy will lead to an automatic downgrade of the policy
              from Comprehensive to third party.
            </li>
          </ul>
        </div>
      </div>
    </modal>

    <modal :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="included-your-cover" >
      <div class="cover-modal p-3">

        <div class="row">
          <div class="col-8">
            <h3>Included your cover</h3>
          </div>
          <div class="col-4">
            <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('included-your-cover')" class="btn btn-sm btn-info float-right"> Close </a>
          </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">Accidental loss, damage or destruction to the vehicle(s) covered under the policy</li>
            <li style="margin-bottom: 7px;">Accidental loss or damage to other person’s property arising from usage of the covered
              vehicle. Property of the participant and family is not covered.</li>
            <li style="margin-bottom: 7px;">Accidental death or bodily injury to third parties in and outside the vehicle except the
              incident driver, the participant, employees of the participant and family members.</li>
            <li style="margin-bottom: 7px;">
              Legal costs necessarily incurred pursuant to covered incidents involving the vehicle.
            </li>
            <li style="margin-bottom: 7px;">
              Courtesy Uber during vehicle repair following accident, fire or theft. (up to Kes.
              30,000)
            </li>
            <li style="margin-bottom: 7px;">
              Cost of replacing the windscreen in the event of an accident, or damage for up to Kes.
              50,000.
            </li>
            <li style="margin-bottom: 7px;">
              Your specified vehicle(s) is/are covered while parked, being services/repaired or in
              motion within the territorial limits shown on the policy schedule
            </li>
          </ul>
        </div>
      </div>
    </modal>


    <modal :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="device-installation" >

      <div class="cover-modal p-3">

        <div class="row">
          <div class="col-8">
            <h3>Device installation</h3>
          </div>
          <div class="col-4">
            <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('device-installation')" class="btn btn-sm btn-info float-right"> Close </a>
          </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">
              Device installation
              A Telematics Box must be installed in the vehicle at the start of the policy. The Box tracks the distance traveled throughout the policy (not exceeding 10,000km) and offers precise accident information for quicker claims processing.
            </li>

          </ul>
        </div>
      </div>
    </modal>


    <modal :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="key-exclusions" >
      <div class="cover-modal p-3">

        <div class="row">
          <div class="col-8">
            <h3>Key exclusions & conditions</h3>
          </div>
          <div class="col-4">
            <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('key-exclusions')" class="btn btn-sm btn-info float-right"> Close </a>
          </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">Loss or injury sustained by the participant, family members, employees and driver
              at the time of the accident.</li>
            <li style="margin-bottom: 7px;">Deliberate or willful acts by the participant or employees leading to loss, damage or
              injury.</li>
            <li style="margin-bottom: 7px;">Any ventures that are harmful to society and are not shariah compliant.</li>
            <li style="margin-bottom: 7px;">Acts of customs or other lawful or enforcement agencies carrying out official duty.</li>

          </ul>
        </div>
      </div>
    </modal>


  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        resizable: true,
        adaptive: true,
        draggable: true,
        isLoading: false,
        fullPage: true,
        paymentMethod:"Full Amount",
        modalScrollable: true,
        termsAndConditions: false,
        quotation:{
          quoteId: "",
          modelId: "",
          traditionalPremium: 0,
          sumInsured: "",
          make:"",
          model:"",
          yearOfManufacture:"",
          vehicleRegistrationNumber:"",
          distancePremium: 0,
          financingBreakdown:{
            checkpoints:[],
            minimumDeposit:0
          }
        },
        quote_summary:{
          cover_period:"One Year",

          items:[
            {
              name:"Promo Discount",
              value:0
            }
          ],

        },
        additional_benefits:[
                /*"Loss of Use",
                "Terrorism, sabotage & political risks",
                "Excess protector",
                "24 Hour Road Rescue Fees",
                "24 Hour Road Rescue (Infama)"*/
        ]
      };
    },
    computed:{
      total(){
        return this.quote_summary.items.reduce((sum, item) => sum + item.value, 0)
      },
      discount(){
        return ((this.quotation.traditionalPremium - this.quotation.distancePremium ) / this.quotation.traditionalPremium ) * 100;
      }
    },
    mounted(){
      //this.quotation = JSON.parse(localStorage.getItem("quote"));
      localStorage.setItem("amountToPay", this.quotation.distancePremium);
      this.getQuoteData();
    },
    components: {
      Loading
    },

    methods:{
      ...mapActions(["getQuoteDetails"]),

      switchPaymentMethod(paymentMethod){
        this.paymentMethod = paymentMethod;
        localStorage.setItem("paymentMethod", paymentMethod)

        if(this.paymentMethod === "Full Amount"){
          localStorage.setItem("amountToPay", this.quotation.distancePremium);
        }
        else{
          localStorage.setItem("amountToPay", this.quotation.financingBreakdown.minimumDeposit);
        }
      },

      getQuoteData(){
        let self = this;
        this.isLoading = true;

        console.log("Fetching quote data");

        this.getQuoteDetails({id: this.$route.params.quoteId}).then((quote) => {
          self.isLoading = false;

          self.quotation.quoteId = quote._id;
          self.quotation.modelId = quote.modelId;
          self.quotation.traditionalPremium = quote.traditionalPremium;
          self.quotation.sumInsured = quote.sumInsured;
          self.quotation.make = self.$route.query.make;
          self.quotation.model = self.$route.query.model;
          self.quotation.yearOfManufacture = quote.yearOfManufacture;
          self.quotation.vehicleRegistrationNumber = quote.vehicleRegistrationNumber;
          self.quotation.distancePremium = quote.distancePremium;
          self.quotation.financingBreakdown = quote.financingBreakdown;

          localStorage.setItem("minimumDeposit", quote.financingBreakdown.minimumDeposit);

          self.quote_summary.items.splice(0,0 ,{
            name:"Gross Premium",
            value: self.quotation.traditionalPremium
          })

          self.quote_summary.items.splice(self.quote_summary.items.length - 1,0 ,{
            name:"Personalized Discount",
            value: self.quotation.traditionalPremium - self.quotation.distancePremium
          });


          localStorage.setItem("quote",JSON.stringify(
                  {
                    quoteId: quote._id,
                    modelId: quote.modelId,
                    traditionalPremium: quote.traditionalPremium,
                    sumInsured: quote.sumInsured,
                    make:self.$route.query.make,
                    model:self.$route.query.model,
                    yearOfManufacture:quote.yearOfManufacture,
                    vehicleRegistrationNumber:quote.vehicleRegistrationNumber,
                    distancePremium: quote.distancePremium
                  }
          ));


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      },
      showCoverDetails(modalName){
        this.$modal.show(modalName);
      },
      hideCoverDetails(modalName){
        this.$modal.hide(modalName);
      },
      goBackToHomepage(){
        location.replace("https://tiaswitch.ke");
      },
      navigateToCoverWizard(){
        if(this.termsAndConditions){
          this.switchPaymentMethod(this.paymentMethod);
          this.$router.push('/vehicleCheckout')
        }
        else{
          this.$toast.open({
            message: "Agree to terms and conditions to proceed",
            type: 'warning',
            duration:5000,
            position:"top-right"
          });
        }
      }
    }
  }
</script>

<style>

  .list-group-item {
    background-color: transparent !important;
    color:#fff !important;
    border:none !important;
  }

  .quote-summary{
    padding: 20px;
    background-color: #2F3641;
    border-radius: 5px;
    color:#fff;
  }

  .quote-summary h4 {
    font-size: 1.2em;
  }

  .quote-summary h4, .quote-summary h5,  .quote-success h4{
    color:#fff;
  }

  .quote-success{
    background-color:#13233A;
    border-radius: 5px;
    padding: 40px 20px;
    color:#fff;
  }

  .quote-btn{
    width: 100%;
  }


</style>
